import React, { useState } from 'react';
import './verify.css';
const VerificationDialog = ({ isVisible, onSubmit, onClose }) => {
    const [code, setCode] = useState("");

    const handleSubmit = () => {
        if (code.length === 6) {
            onSubmit(code);
            setCode('');
        } else {
            alert("請輸入6位數的驗證碼");
        }
    };


    if (!isVisible) return null;

    const handleChange = (e) => {
        const value = e.target.value;

        // 只允许数字输入
        if (/^\d*$/.test(value)) {
            // 如果输入超过6位，显示通知
            if (value.length > 6) {
                alert("只能输入6位数字");
                return;
            }
            setCode(value);
        }
    };

    return (
        <div className="verify-overlay">
            <div className='verify-content'>
                <h2>輸入驗證碼</h2>
                <input
                    type="text"
                    value={code}
                    onChange={handleChange}
                    placeholder="请输入6位数字"
                />
                <br /><br />
                <button onClick={handleSubmit}>提交</button>
                <button onClick={onClose}>取消</button>
            </div>
        </div >
    );
};

export default VerificationDialog;
