import React from 'react';

const Footer = () => {
  return (


    <footer className="footer">
      <div className="footer-content">
        <p>&copy; 2024 欠債管理系統 保留所有权利</p>
        <div className="footer-links">
          <a href="#privacy">隐私政策</a>
          <a href="#terms">服务条款</a>
          <a href="#contact">联系我们</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
