// Modal.js
import React from 'react';
import './modal.css';
import { RadioGroup, Radio, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const Modal = ({ isVisible, onClose, onSubmit }) => {
  if (!isVisible) return null;

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {
      name: formData.get('name'),
      detail: formData.get('detail'),
      amount: parseFloat(formData.get('amount')),
      gender: parseFloat(formData.get('gender')),
      email:formData.get('email')
    };
    onSubmit(data);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>新增資料</h2>
        <form onSubmit={handleSubmit}>
          <label>
            名稱:
            <input type="text" name="name" required />
          </label>
          <label>
            詳細:
            <input type="text" name="detail" required />
          </label>
          <label>
            金額:
            <input type="number" name="amount" required />
          </label>
          <label>
            信箱:(收驗證信)
            <input type="email" name="email" required />
          </label>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">性別</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
            >
              <FormControlLabel value="2" control={<Radio />} label="女" />
              <FormControlLabel value="1" control={<Radio />} label="男" />

            </RadioGroup>
          </FormControl>
          <button type="submit">提交</button>
          <button type="button" onClick={onClose}>取消</button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
