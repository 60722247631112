import React, { useEffect, useState } from "react";
import axios from "axios";
import { animated } from "react-spring";
import "./index.css";
import Header from './other/header';
import Footer from './other/footer';
import Marquee from './other/marquee';
import Modal from './modal.js';
import { duration } from "@mui/material";
import VerificationDialog from './verifyEvent/verify.js';

// 获取颜色函数
const getDebtColor = (debt) => {
  if (debt >= 0 && debt <= 10000) {
    return "#B4F8C8";
  } else if (debt > 10000 && debt <= 50000) {
    return "#A0E7E5";
  } else if (debt > 50000) {
    return "#70D6FF";
  }
  return "#008080";
};

const App = () => {
  const [userData, setUserData] = useState([]);
  const [responseData, setResponseData] = useState(null); // 用來儲存 API 回傳的資訊
  const [isModalVisible, setModalVisible] = useState(false);
  const [isVerificationVisible, setVerificationVisible] = useState(false);
  const fetchData = async () => {
    try {
      // https://jgordon.vealcoo.net/event/?offset=1&limit=20
      const response = await axios.get(" https://jgordon.vealcoo.net/event/", {
        params: {
          offset: 0,
          limit: 20,
        },
      });
      console.log(response);
      setUserData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleButtonClick = () => {
    fetchData();
  };


  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSubmit = async (data) => {
    console.log('新增資料:', data);
    setModalVisible(false);

    try {
      const response = await axios.post(" https://jgordon.vealcoo.net/event/", {
        name: data.name,
        amount: data.amount,
        detail: data.detail,
        gender: data.gender,
        email: data.email,
      });
      setResponseData(response.data);
      setVerificationVisible(true);

      // fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleVerificationSubmit = async (code) => {
    console.log("驗證碼:", code);
    setVerificationVisible(false);
    try {
      const response = await axios.put(`https://jgordon.vealcoo.net/event/verify/${responseData.Id}/${code}`, {

      });
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div className="App">
      <Header />
      <Marquee messages={["欠錢還錢，天經地義"]} duration={4} />
      <div className="app-content">
        <button className="btn btn-add" onClick={handleOpenModal}>增加欠款人</button>
        <button className="btn btn-refresh" onClick={handleButtonClick}>要是能重來</button>
      </div>
      <VerificationDialog
        isVisible={isVerificationVisible}
        onSubmit={handleVerificationSubmit}
        onClose={() => setVerificationVisible(false)}
      />
      <div className="card-container">
        {userData != null ? (
          userData.map((user, index) => (
            <ShakingCard key={index} user={user} />
          ))
        ) : (
          <div className="no-content"><p>尚無任何人</p></div>
        )}
      </div>

      <Modal isVisible={isModalVisible} onClose={handleCloseModal} onSubmit={handleSubmit} />
      <Footer />
    </div>
  );
};

const ShakingCard = ({ user }) => {
  const getGenderLabel = (gender) => {
    switch (gender) {
      case 1:
        return '男';
      case 2:
        return '女';
      default:
        return '未知';
    }
  };
  return (
    <animated.div
      className="card"
    // style={{ backgroundColor: getDebtColor(user.amount) }}
    >
      <div className="row">
        <div className="column">
          <div className="label">Name:</div>
          <div className="value">{user.name}</div>
        </div>
        <div className="column">
          <div className="label">Gender:</div>
          <div className="value">{getGenderLabel(user.gender)}</div>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <div className="label">Detail:</div>
          <div className="value">{user.detail}</div>
        </div>
        <div className="column">
          <div className="label">Debt:</div>
          <div className="value">${user.amount}</div>
        </div>
      </div>
    </animated.div>
  );
};

export default App;
