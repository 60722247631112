import React, { useState, useEffect } from 'react';
import './marquee.css'; // 引入CSS样式

const Marquee = ({ messages, duration = 10 }) => {
    const [currentMessage, setCurrentMessage] = useState(messages[0]);
    const [index, setIndex] = useState(0);
    useEffect(() => {
        // 设置定时器，等到动画完成后更新消息
        const interval = setTimeout(() => {
            setIndex((prevIndex) => (prevIndex + 1) % messages.length);
            setCurrentMessage(messages[(index + 1) % messages.length]);
        }, duration * 1000); // 动画时间后更新消息

        return () => clearTimeout(interval);
    }, [index, messages, duration]);

    return (
        <div className="marquee-container">
            <div className="marquee" style={{ animationDuration: `${duration}s` }}>
                <p>{currentMessage}</p>
            </div>
        </div>
    );
};

export default Marquee;
